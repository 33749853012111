import React from 'react';
import PropTypes from 'prop-types';
import LandingPageTemplate from '../../components/04_templates/PageLayouts/LandingPage';
import Paragraphs from '../../components/02_molecules/Paragraphs';
import '../../styles/styles.scss';

const SectionIndexPage = ({ blocks = [], breadcrumbs = [], layout = 'twoColumns' }) => {
  // Automatically inject breadcrumb navigation.
  const pageBlocks = blocks.map((block) => {
    if (!block) {
      return null;
    }

    const blockCopy = block;
    if (blockCopy.blockType === 'hero_standard_info') {
      if (blockCopy.showBreadcrumbs) {
        blockCopy.breadcrumbs = {
          color: 'white',
          links: breadcrumbs,
        };
      }
    }

    return blockCopy;
  });

  // Add Social Media share side bar
  pageBlocks.push({
    blockType: 'social_media_share',
    activeProviders: ['facebook', 'twitter', 'linkedin', 'link'],
    hasMobileBorders: true,
    isDesktopSticky: true,
  });

  return (
    <LandingPageTemplate>
      <Paragraphs blocks={pageBlocks} layout={layout} />
    </LandingPageTemplate>
  );
};

// Opt-out of Automatic Static Optimization.
// See https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
SectionIndexPage.getInitialProps = async () => ({});

SectionIndexPage.propTypes = {
  entity: PropTypes.shape({
    // TODO: Define entity object shape.
  }),
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.string.isRequired,
    }),
  ),
  // eslint-disable-next-line react/no-unused-prop-types
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape({
        href: PropTypes.string,
        as: PropTypes.string,
      }),
    }),
  ),
  layout: PropTypes.string,
};

export default SectionIndexPage;
